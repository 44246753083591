:root {
  --background-color: #ffffff;
  --black-color: #2e2f33;
  --gray-color: #ecedf0;
  --blue-color: #008CBA;
}



body {
  margin: 0;
  font-family: Helvetica;
  background-color: var(--background-color);
  /* color: white; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.user-zone {
  display: flex;
  flex-direction: column;
  align-items:center;
}

.profile-pic{
  padding: 5px;
  border-radius: 50%;
}

button {
  border: none;
  border-radius: 5px;
  font-size: large;
  font-family: Helvetica;
  padding: 3px 10px 3px 10px;
  margin: 2px;
}

button:hover {
  cursor: pointer;
}

a:link, a:visited {
  text-decoration: none;
}

textarea:hover {
  cursor:pointer;
}

textarea {
  border: none;
  border-radius: 5px;
  font-family: Helvetica;
  resize: none;
  text-align: center;
  font-weight: bold;
}

.jwt {
  font-size: small;
  text-align: left;
}

.tenant-selector {
  font-size: calc(10px + 1vmin);
  border-radius: 5px;
  border: none;
}


.change-color-img {
  filter: grayscale(100%) sepia(100%) hue-rotate(240deg);
}

textarea, button, select {
  background-color: var(--gray-color);
  box-shadow: 1px 1px 5px #888888;
  color: black;
}

img {
  border-radius: 80%;
}